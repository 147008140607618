@mixin tablet {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px) {
    @content;
  }
}


.nav_component {
  background-color: #000;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.nav_container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.nav_logo {
  width: 10rem;
}

.nav_menu_link {
  color: #fff;
  padding: 1rem;
}

.nav_button {
  padding: 1rem;
}


.navbar {
  z-index: 999;
  background-color: rgba(221, 221, 221, 0);
  padding-top: 2.25rem;
  padding-bottom: 1rem;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;

  @include phone {
    padding-top: 1rem;
  }
}

.nav-content {
  z-index: 1;
  background-image: linear-gradient(90deg, rgba(0, 249, 129, .1), rgba(0, 86, 251, .1));
  border-radius: 1.375rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.37rem 2rem;
  display: flex;
  position: relative;
  overflow: hidden;

  @include phone {
    border-radius: 1rem;
    padding: 1rem 1.25rem;
  }
}

.menu-button.close.w--open {
  @include phone {
    top: 2.12rem;
    right: 2.5rem;
  }
}

.nav-logo {
  max-width: 10.75rem;
  padding-left: 0;

  @include phone {
    max-width: 10.6rem;
  }
}

.nav_link {
  color: var(--base-color-neutral--white);
  text-transform: uppercase;
  padding: 0;
  font-weight: 500;
  line-height: 1;
  transition: all .4s;

  @include tablet() {
    font-size: 1.5rem;
  }
}

.nav_link:hover {
  color: var(--color--guppie-green);
}

.nav_link.w--current {
  color: var(--color--guppie-green);
  line-height: 1;
}

.nav_menu-holder {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;

  @include tablet {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    display: flex;
  }
}

.nav_menu-icon {
  @include tablet {
    justify-content: center;
    align-items: center;
    width: 2rem;
    display: flex;
  }
}


.menu-button {
  display: none;

  @include tablet {
    display: block;
  }
}


.nav_menu-wrap {

  @include tablet {
    display: none;
    z-index: 9999;
    background-color: var(--color--dark-blue);
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
}




.navclosebtn {
  width: 2rem;
  display: none;
  position: absolute;
  top: 2.5rem;
  bottom: auto;
  left: auto;
  right: 4rem;

  @include tablet {
    display: block;
  }

  @include phone {
    display: block;
    top: 1.5rem;
    right: 1.5rem;
  }

}

.nav_menu-wrap.show {
  display: block;
}


.active {
  color: var(--color--guppie-green); /* Change color as per your design */
  /* Add any other styles to highlight the active link */
}