@mixin tablet {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px){
    @content;
  }
}

.footer {
    padding-top: 2.75rem;
    padding-bottom: 1.75rem;
    position: relative;
    overflow: hidden;

    @include phone{
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
  }
  
  .footer-wrapper {
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;

    @include phone{
      z-index: 1;
      position: relative;
    }
  }
  
  .footer-logo {
    max-width: 12.3rem;

    @include phone{
      max-width: 10rem;
    }
  }
  
  .footer-desc-wrap {
    text-align: center;
    margin-top: 2.75rem;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1;

    @include phone{
      margin-top: 2.5rem;
      margin-bottom: 1.875rem;
      font-size: 1.75rem;
      line-height: 1.25;
    }
  }

  .social_wrapper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    display: flex;
  }
  
  .social_link {
    background-image: linear-gradient(90deg, #0056fb, var(--color--guppie-green));
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    padding: 1.5px;
  }
  
  .social-icon-holder {
    background-color: #172c55;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  .social-icon {
    justify-content: center;
    align-items: center;
    width: 2rem;
    display: flex;
  }

  .footer-menu-wrap {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    border-bottom: 1px solid #fff;
    justify-content: space-around;
    margin-top: 4.87rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;

    @include phone{
      z-index: 1;
      grid-column-gap: 1.875rem;
      grid-row-gap: 1.875rem;
      flex-flow: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 2.75rem;
      margin-bottom: 1.125rem;
      position: relative;
    }
  }
  
  .footer-link {
    color: var(--base-color-neutral--white);
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    transition: all .4s;
  }
  
  .footer-link:hover {
    color: var(--color--guppie-green);
  }
  
  .copyright-text {
    text-align: center;
    font-weight: 500;
  }
  
  .footer_shadow {
    display: none;

    @include phone{
      filter: blur(120px);
      background-color: rgba(0, 86, 251, .45);
      border-radius: 100%;
      width: 14.75rem;
      height: 14.75rem;
      display: block;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 45%;
      right: 0%;
    }
  }

  .footer_shadow-main {
    filter: blur(250px);
    background-color: rgba(0, 86, 251, .5);
    border-radius: 100%;
    width: 39.18rem;
    height: 39.18rem;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 50%;
    right: 0%;
    transform: translate(-50%);

    @include phone{
      width: 14.75rem;
      height: 14.75rem;
    }
  }