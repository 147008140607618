:root {
  --color--dark-black: #010101;
  --base-color-neutral--white: #fff;
  --link-color--link-primary: var(--base-color-brand--blue);
  --background-color--background-primary: var(--base-color-neutral--black);
  --text-color--text-alternate: var(--base-color-neutral--white);
  --text-color--text-primary: var(--base-color-neutral--black);
  --text-color--text-secondary: var(--base-color-neutral--neutral-darker);
  --background-color--background-success: var(--base-color-system--success-green);
  --text-color--text-success: var(--base-color-system--success-green-dark);
  --border-color--border-primary: var(--base-color-neutral--neutral-lightest);
  --background-color--background-alternate: var(--base-color-neutral--white);
  --background-color--background-secondary: var(--base-color-brand--blue);
  --background-color--background-tertiary: var(--base-color-brand--pink);
  --background-color--background-error: var(--base-color-system--error-red);
  --text-color--text-error: var(--base-color-system--error-red-dark);
  --border-color--border-alternate: var(--base-color-neutral--neutral-darker);
  --background-color--background-warning: var(--base-color-system--warning-yellow);
  --text-color--text-warning: var(--base-color-system--warning-yellow-dark);
  --color--guppie-green: #00f981;
  --color--dark-blue: #071a32;
  --color--blue: #0056fb;
  --base-color-neutral--black: #000;
  --border-color--border-secondary: var(--base-color-brand--blue);
  --link-color--link-secondary: var(--base-color-neutral--black);
  --link-color--link-alternate: var(--base-color-neutral--white);
  --base-color-brand--blue-light: #d9e5ff;
  --base-color-brand--blue: #2d62ff;
  --base-color-brand--blue-dark: #080331;
  --base-color-brand--pink-light: #ffaefe;
  --base-color-brand--pink: #dd23bb;
  --base-color-brand--pink-dark: #3c043b;
  --base-color-neutral--neutral-lightest: #eee;
  --base-color-neutral--neutral-lighter: #ccc;
  --base-color-neutral--neutral-light: #aaa;
  --base-color-neutral--neutral: #666;
  --base-color-neutral--neutral-dark: #444;
  --base-color-neutral--neutral-darker: #222;
  --base-color-neutral--neutral-darkest: #111;
  --base-color-system--success-green: #cef5ca;
  --base-color-system--success-green-dark: #114e0b;
  --base-color-system--warning-yellow: #fcf8d8;
  --base-color-system--warning-yellow-dark: #5e5515;
  --base-color-system--error-red: #f8e4e4;
  --base-color-system--error-red-dark: #3b0b0b;
  --base-color-system--focus-state: #2d62ff;
  --color--eerie-black: #1e1e1e;
  --color--rich-black: #030b15;
  --color--cream: #ff926d;
}
#root{
width: 100%;}


@mixin tablet {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px){
    @content;
  }
}


.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

input{
  width:100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.page-wrapper {
  z-index: 1;
  position: relative;
}

img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.w-embed:before,
.w-embed:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.w-embed:after {
  clear: both;
}






p {
  margin-top: 0;
  margin-bottom: 10px;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.1;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
}

h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}


.heading-style-h1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.25;

  @include tablet{
    font-size: 3rem; 
  } 

 @include phone{
  font-size: 1.875rem;
  }
}

.heading-style-h2 {
  font-size: 2rem;
  line-height: 1.25;

  @include phone{
    font-size: 1.75rem;
    line-height: 1.2;
    }
}

.heading_style-h2-48 {
  font-size: 3rem;
  line-height: 1.37;

  @include phone{
    font-size: 1.875rem;
    line-height: 1.5;
    }
}

p {
  margin-bottom: 0;
}

a {
  color: var(--link-color--link-primary);
}

ul, ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1.5rem;
}

li {
  margin-bottom: .25rem;
}

img {
  max-width: 100%;
  display: inline-block;
}

.hide {
  display: none;
}  

.text-weight-semibold {
  font-weight: 600;
}

.max-width-full {
  width: 100%;
  max-width: none;
}

.text-color-primary {
  color: var(--text-color--text-primary);
}

.text-color-secondary {
  color: var(--text-color--text-secondary);
}

.text-size-small {
  font-size: .875rem;
}

.container-medium,.container-small, .container-large {
  margin-right: auto !important;
  margin-left: auto !important;
}

.container {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  @include tablet{
    padding-left: 2rem;
    padding-right: 2rem;  
  } 

 @include phone{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  }
}

.container-large {
  width: 100%;
  max-width: 85rem;
  margin-left: auto;
  margin-right: auto;
}

.text-size-regular-20 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2;
  @include phone{
    font-size: 1rem;
  }
}

.text-weight-medium {
  font-weight: 500;
}

.text-size-regular {
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
}

.button {
  background-color: var(--background-color--background-primary);
  color: var(--text-color--text-alternate);
  text-align: center;
  border-radius: .25rem;
  padding: .75rem 1.5rem;
  font-weight: 600;
}

.secoundry-button {
  z-index: 1;
  color: var(--base-color-neutral--white);
  background-image: linear-gradient(104deg, #0056fb, #00f981);
  border-radius: .75rem;
  padding: 1px;
  font-weight: 600;
  position: relative;
  box-shadow: 0 0 6px #00add8;
}

.button_inner-wrap {
  background-color: var(--color--dark-blue);
  text-align: center;
  border-radius: .75rem;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 2.5rem;
  transition: all .4s;
  display: flex;

  @include phone{
    padding: 1.125rem 1.5rem 1.125rem 1.25rem;  
  }
}

.button_inner-wrap.secoundry{
  @include phone{
    padding-left: 1rem;
    padding-right: 1rem; 
  }
}

.button_inner-wrap:hover {
  color: var(--base-color-neutral--black);
  background-color: rgba(7, 26, 50, 0);
}

.text-color-green {
  color: var(--color--guppie-green);
}

.body_bg {
  opacity: .05;
  pointer-events: none;
  background-image: url('../src/assets/images/Body-Bg-Img.png');
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.blackbtn-icon {
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  display: none;
}

.colorbtn-icon {
  justify-content: center;
  align-items: center;
  width:1.25rem;
  height: 1.25rem;
  display: flex;
}

.secoundry-button:hover .blackbtn-icon {
  display: flex;
}

.secoundry-button:hover .colorbtn-icon {
  display: none;
}

body.page_scrolled .nav-content {
  background-color: #071a32;
  background-image: none;
}

.sec_left-divider, .sec_right-divider{
   @include tablet {
    width: 35%;
   }
   
}



.privacypolicy-section{

  padding-top:15rem ;

  @include phone{
    padding-top: 8rem;
  }
  h1{
    margin-bottom: 5rem;
    text-align: center;
    @include phone{
      font-size: 4rem;
    }
  }
  h3{
    margin-top: 4rem;
    margin-bottom: 2rem;
    @include phone{
      font-size:1.5rem;
    }
  }

  p{
    margin-bottom: 1rem;
  }

  ul{
    margin-bottom: 1rem;
  }
}

.toast {
	background-color: #323232 !important;
	color: #fff !important;
}